<template>
  <div id="tradein">
    <!-- 以旧换新 -->
    <Header id="Header" class="header_position"></Header>
    <NewLook></NewLook>
    <NewIdeas :thoughtData="thoughtData"></NewIdeas>
    <ActionRenewal :getTradeInObj="getTradeInObj"></ActionRenewal>
    <FiveActions :getTradeInObj="getTradeInObj"></FiveActions>
    <NewLife :getTradeInObj="getTradeInObj" ></NewLife>
    <UpdateForm :getTradeInObj="getTradeInObj"></UpdateForm>
  </div>
</template>

<script>
import Header from "../../components/Header";
import NewLook from "./components/NewLook";
import NewIdeas from "./components/NewIdeas";
import ActionRenewal from "./components/ActionRenewal";
import FiveActions from "./components/FiveActions";
import NewLife from "./components/NewLife";
import UpdateForm from './components/UpdateForm'
import { getTradeIn } from "../../http/api";
export default {
  name: "",
  props: {},
  components: {
    Header,
    NewLook,
    NewIdeas,
    ActionRenewal,
    FiveActions,
    NewLife,
    UpdateForm,
  },
  data() {
    return {
      thoughtData: [],
      getTradeInObj: {},
    };
  },
  methods: {
    getTradeInData() {
      getTradeIn().then((res) => {
        this.getTradeInObj = res.data;
        this.thoughtData = res.data.thought;
      });
    },
  },
  mounted() {
    this.getTradeInData();
  },
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang='scss'>
#tradein {
  width: 100%;
  background-image: url("../../assets/img/tradein_back1.png"),
    url("../../assets/img/tradein_back1.png");
  background-repeat: no-repeat;
  background-size: 1087px 750px;
  // background-position-y: 929px, 1900px;
  background-position-y: 820px, 1900px;
  background-position-x: 0, 833px;
  position: relative;
  .header_position{
    position: absolute;
    top: 0;
    width: 100%;
  }
}
</style>
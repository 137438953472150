<template>
  <div id="NewLook">
    <!-- 改旧貌·换新颜 -->
    <div class="NewLook">
      <div class="look_title">改旧貌·换新颜</div>
      <div class="line"></div>
      <div class="min_title">
        <div>以新带旧·以新促旧</div>
        <div>以新改旧·以新换旧</div>
      </div>
      <div class="eight_P">
        一、八益集团在行动，在疫情和市场低糜期为消费者送健康、解烦恼、增实惠。<br />
        二、八益集团在整合，具备床垫「以旧换新」的一切基础和优势。<br />
        三、八益集团在带领，「以旧换新」带领了全行业发展，实现八益自身跨越。<br />
        四、八益集团在开创，把「以旧换新」打造成八益在全国行业销售中的一张靓丽名片，开创全国床垫新销售模式的先河。<br />
        五、八益集团在振动，八益全员需统一思想认识，全力打好「换购」战。<br />
        六、八益集团在推进，集团上下一切以换购为中心，扎实有效推进。<br />
        七、八益集团在关注，致力于关注人类健康睡眠，上升企业使命高度。<br />
        八、八益集团为大家，为疫后健康消费保驾，为社会环保安全负责。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang='scss'>
#NewLook {
  width: 100%;
  height: 829px;
  background-image: url("../../../assets/img/newLook_back.png");
  background-repeat: no-repeat;
  background-size: cover;
  .NewLook {
    width: 563px;
    margin: 0 auto;
    padding-top: 208px;
    .look_title {
      font-size: 50px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #9a7247;
      text-align: center;
      animation: tlook_title 1s linear;
    }
    @keyframes tlook_title {
      0% {
        transform: translateY(100%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
    .line {
      width: 255px;
      height: 1px;
      background: #707070;
      margin: 32px auto 40px auto;
      animation: tlook_title 1s linear;
    }
    .min_title {
      font-size: 26px;
      font-family: MicrosoftYaHei;
      color: #3a3939;
      text-align: center;
      animation: tlook_title 1s linear;
      margin-bottom: 30px;
    }
    .eight_P {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      line-height: 34px;
      color: #666666;
      animation: tlook_title 1s linear;
    }
  }
}
</style>
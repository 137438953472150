<template>
  <div id="UpdateForm">
    <!-- 换新表单 -->
    <div class="update_guanggao"></div>
    <div class="form_box">
      <div class="min_box">
        <Spot
          style="margin-top: 55px"
          :SpotZhTitle="SpotZhTitle"
          :SpotEnTitle="SpotEnTitle"
        ></Spot>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm form_min_box"
        >
          <el-form-item label="姓名" prop="name">
            <el-input
              v-model="ruleForm.name"
              style="width: 407px; heihgt: 56px"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="tel" style="margin-right: 20px">
            <el-input
              v-model="ruleForm.tel"
              style="width: 407px; heihgt: 56px"
              placeholder="请输入电话"
            ></el-input>
          </el-form-item>
          <el-form-item label="区域" prop="add">
            <el-input
              v-model="ruleForm.add"
              style="width: 407px; heihgt: 56px"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注信息" prop="info">
            <el-input
              v-model="ruleForm.info"
              style="width: 424px; heihgt: 56px"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="red_tost">温馨提示：只限成都区域参加</div>
        <div class="subMit" @click="sub_mit">提交</div>
      </div>
    </div>
  </div>
</template>

<script>
import Spot from "../../../components/spot";
import { submitForm } from "../../../http/api";
export default {
  name: "",
  props: {},
  components: {
    Spot,
  },
  data() {
    return {
      ruleForm: {
        name: "",
        tel: "",
        add: "",
        info: "",
      },
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        tel: [{ required: true, message: "请输入电话", trigger: "blur" }],
        add: [{ required: true, message: "请输入区域", trigger: "blur" }],
      },
      SpotZhTitle: "我要参加以旧换新",
      SpotEnTitle: "I'm going to trade in",
    };
  },
  methods: {
    sub_mit() {
      if (
        this.ruleForm.name != "" &&
        this.ruleForm.tel != "" &&
        this.ruleForm.add != ""
      ) {
        if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.ruleForm.tel)) {
          this.$message.error("请填写正确电话号码");
          // this.ruleForm.name = "";
          this.ruleForm.tel = "";
          // this.ruleForm.add = "";
          // this.ruleForm.info = "";
        } else {
          submitForm({
            type: 3,
            name: this.ruleForm.name,
            tel: this.ruleForm.tel,
            area: this.ruleForm.add,
            remark: this.ruleForm.info,
          }).then((res) => {
            if (res.status === "SUCCESS") {
              this.$message.success("提交成功");
              this.ruleForm.name = "";
              this.ruleForm.tel = "";
              this.ruleForm.add = "";
              this.ruleForm.info = "";
            }
          });
        }
      } else {
        this.$message.error("请填写带星号得全部信息");
        this.ruleForm.name = "";
        this.ruleForm.tel = "";
        this.ruleForm.add = "";
        this.ruleForm.info = "";
      }
    },
  },
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang='scss'>
#UpdateForm {
  width: 100%;
  .update_guanggao {
    width: 100%;
    // height: 276px;
    height: 14.37vw;
    background-image: url("../../../assets/img/update_back.png");
    background-size: cover;
  }
  .form_box {
    width: 100%;
    height: 715px;
    background: rgba(223, 223, 223, 0.4);
    display: flex;
    justify-content: center;
    .min_box {
      width: 1200px;
      // height: 541px;
      height: 510px;
      margin-top: 80px;
      background: #ffffff;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
      .form_min_box {
        width: 1100px;
        height: 143px;
        margin: 0 auto;
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      .red_tost {
        margin-left: 150px;
        font-size: 13px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 18px;
        color: #f12121;
      }
      .subMit {
        margin: 0 auto;
        margin-top: 57px;
        width: 182px;
        height: 46px;
        background: #225928;
        opacity: 1;
        border-radius: 8px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 46px;
        color: #ffffff;
        text-align: center;
      }
    }
  }
}
</style>
<template>
  <div id="NewLife">
    <!-- 生活换新 -->
    <Spot
      style="margin-top: 70px"
      :SpotZhTitle="SpotZhTitle"
      :SpotEnTitle="SpotEnTitle"
    ></Spot>
    <div class="center_box">
      <div class="content_top_box">
        <div class="left_box">
          <div class="left_center">
            <div class="title">{{ life_title }}</div>
            <div class="line_"></div>
            <div class="info">{{ life_info }}</div>
          </div>
          <div class="pagibtn" @click="changeContent">
            <img
              src="../../../assets/img/pagibtn.png"
              class="my_cover"
              alt=""
            />
          </div>
        </div>
        <div class="right_box"><img class="my_cover" :src="life_img" alt="" /></div>
        <div class="bottom_box">
          <div
            class="bottom_item"
            v-for="(item, index) in newLidfeFive"
            :key="index"
            @mouseenter="bottom_itementer(index)"
            @mouseleave="bottom_itemleave(index)"
          >
            <div class="top_box">
              <div class="top_left_box">
                <div class="top_line"></div>
                <div class="top_title">{{ item.title }}</div>
              </div>
              <div class="top_right_box">
                <img :src="item.icon1" v-if="active_icon === index" alt="" />
                <img :src="item.icon" v-else alt="" />
              </div>
            </div>
            <div class="bottom_info_box">
              {{ item.info }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spot from "../../../components/spot";
export default {
  name: "",
  props: {
    getTradeInObj: {
      type: Object,
    },
  },
  components: {
    Spot,
  },
  data() {
    return {
      SpotZhTitle: "生活换新",
      SpotEnTitle: "A new life",
      life_img: "",
      life_img_flag: 0,
      life_title: "",
      life_info: "",
      active_icon: -1,
      newLidfeFive: [
        {
          title: "行业焕新",
          icon: require("../../../assets/img/color1.png"),
          icon1: require("../../../assets/img/white1.png"),
          info:
            "敢为天下先，覆盖上亿人口的销售方式革新，一次性解决旧床垫处置行业难题。",
        },
        {
          title: "消费焕新",
          icon: require("../../../assets/img/color2.png"),
          icon1: require("../../../assets/img/white3.png"),
          info:
            "开行业先河，首创上门回收并上门回购，有效解决消费难题降低消费负担。",
        },
        {
          title: "生活焕新",
          icon: require("../../../assets/img/color3.png"),
          icon1: require("../../../assets/img/white3.png"),
          info: "从高品质的睡眠8小时做起，全面关注和升级衣、食、住、行各方面。",
        },
        {
          title: "心态焕新",
          icon: require("../../../assets/img/color4.png"),
          icon1: require("../../../assets/img/white4.png"),
          info: "好睡眠、好生活、好心态，从生活环境优化到身心健康全面升级。",
        },
        {
          title: "荣誉焕新",
          icon: require("../../../assets/img/color5.png"),
          icon1: require("../../../assets/img/white5.png"),
          info: "既是焕新公益的参与者，也是亲友邻里间的健康生活倡议者。",
        },
      ],
      topscroll: 0,
      lifelength: 0,
    };
  },
  methods: {
    scrollHandle(e) {
      let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
      this.topscroll = top;
    },
    bottom_itementer(index) {
      this.active_icon = index;
    },
    bottom_itemleave(item) {
      this.active_icon = -1;
    },
    changeContent() {
      if (this.life_img_flag > 0) {
        this.life_img_flag--;
        this.life_img = this.getTradeInObj.life[this.life_img_flag].image;
        this.life_title = this.getTradeInObj.life[this.life_img_flag].title;
        this.life_info = this.getTradeInObj.life[this.life_img_flag].info;
      }
      if (this.life_img_flag == 0) {
        this.life_img_flag = this.lifelength ;
        this.life_img_flag--;
        this.life_img = this.getTradeInObj.life[this.life_img_flag].image;
        this.life_title = this.getTradeInObj.life[this.life_img_flag].title;
        this.life_info = this.getTradeInObj.life[this.life_img_flag].info;
      }
      //   if(this.life_img_flag > 4){

      //   }
    },
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.scrollHandle); //绑定页面滚动事件
    });
  },
  watch: {
    topscroll(val) {
      if (val > 6110) {
        $("#NewLife .bottom_item").addClass("NewLifebottom_itema");
      }
    },
    getTradeInObj(val) {
      this.life_img_flag = val.life.length - val.life.length;
      this.lifelength = val.life.length;
      this.life_img = this.getTradeInObj.life[this.life_img_flag].image;
      this.life_title = this.getTradeInObj.life[this.life_img_flag].title;
      this.life_info = this.getTradeInObj.life[this.life_img_flag].info;
    },
  },
  computed: {},
  filters: {},
};
</script>

<style scoped lang='scss'>
#NewLife {
  width: 100%;
  margin-bottom: 100px;
  .center_box {
    width: 1200px;
    margin: 0 auto;
    margin-top: 60px;
    .content_top_box {
      width: 100%;
      //   height: 734px;
      height: 892px;
      position: relative;
      display: flex;
      .left_box {
        width: 27.25%;
        height: 734px;
        background-image: url("../../../assets/img/newLife_left_back.png");
        background-size: cover;
        position: relative;
        z-index: 9;
        .left_center {
          width: 247px;
          margin: 0 auto;
          margin-top: 119px;

          .title {
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 80px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: #ffffff;
          }
          .line_ {
            width: 98px;
            height: 0px;
            border: 1px solid #ffffff;
          }
          .info {
            margin-top: 85px;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 30px;
            color: rgba(255, 255, 255, 0.7);
          }
        }
        .pagibtn {
          width: 34px;
          height: 34px;
          position: absolute;
          left: 30px;
          bottom: 106px;
        }
      }
      .right_box {
        width: 72.75%;
        height: 734px;
        z-index: 9;
      }
      .bottom_box {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 216px;
        display: flex;
        justify-content: space-around;
        .bottom_item:hover {
          background: #bca066;
          transition: all 0.3s linear;
          cursor: pointer;
          .top_box {
            .top_left_box {
              .top_line {
                border: 1px solid #fff;
                transition: all 0.3s linear;
              }
              .top_title {
                color: #fff;
                transition: all 0.3s linear;
              }
            }
          }
          .bottom_info_box {
            color: #fff;
            transition: all 0.3s linear;
          }
        }
        .bottom_item {
          width: 216px;
          height: 216px;
          background: #fff;
          transition: all 0.3s linear;
          z-index: 10;
          box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.16);
          display: flex;
          flex-direction: column;
          align-items: center;
          .top_box {
            width: 174px;
            height: 52px;
            display: flex;
            justify-content: space-between;
            margin-top: 24px;
            .top_left_box {
              .top_line {
                width: 34px;
                height: 0px;
                border: 1px solid #006441;
                transition: all 0.3s linear;
                margin: 12px 0 9px 0;
              }
              .top_title {
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 30px;
                color: #333333;
                transition: all 0.3s linear;
              }
            }
            .top_right_box {
              width: 52px;
              height: 52px;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
          .bottom_info_box {
            width: 182px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 20px;
            color: #888888;
            transition: all 0.3s linear;
            margin-top: 32px;
          }
        }
      }
    }
  }
}
</style>
<template>
  <div id="NewIdeas">
    <!-- 思想换新  -->
    <div class="ideas_title">
      <Spot
        style="margin-top: 70px; margin-bottom: 117px"
        :SpotZhTitle="SpotZhTitle"
        :SpotEnTitle="SpotEnTitle"
      ></Spot>
    </div>
    <div class="swiper_box">
      <swiper
        ref="mySwiper"
        style="width: 100%; height: 100%"
        :options="Options"
      >
        <swiper-slide
          class="border_box"
          v-for="(item, index) in thoughtData"
          :key="index"
          :style="{ backgroundImage: 'url(' + item.image + ')' }"
        >
          <div class="content_box">
            <div class="module_title">{{ item.title }}</div>
            <div class="info">{{ item.info }}</div>
          </div>
          <!-- {{item.title}} -->
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import Spot from "../../../components/spot";

export default {
  name: "",
  props: {
    thoughtData: {
      type: Array,
    },
  },
  components: {
    Spot,
  },
  data() {
    return {
      SpotZhTitle: "思想换新",
      SpotEnTitle: "New ideas",
      Options: {
        slidesPerView: 3,
        slidesPerGroup: 1,
      },
    };
  },
  methods: {},
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
};
</script>

<style scoped lang='scss'>
#NewIdeas {
  width: 100%;
  .ideas_title {
    // height: 260px;
    width: 100%;
  }
  .swiper_box {
    width: 100%;
    height: 680px;
    .border_box {
      width: 33.33%;
      height: 100%;
      background-size: cover;
      .content_box {
        height: 100%;
        margin: 0 100px;
        background: linear-gradient(
          360deg,
          rgba(255, 255, 255, 0.9) 50%,
          rgba(255, 255, 255, 0.7) 60%,
          rgba(255, 255, 255, 0.5) 70%,
          rgba(255, 255, 255, 0.3) 80%,
          rgba(255, 255, 255, 0.1) 90%,
          rgba(255, 255, 255, 0) 100%
        );
        border: 1px solid transparent;
        .module_title {
          margin: 0 54px;
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 28px;
          color: #225928;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-align: center;
          margin-top: 307px;
          margin-bottom: 42px;
        }
        .info {
          // width: 352px;
          // width: 18.33vw;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 25px;
          color: #666666;
          margin: 0 54px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 12;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
</style>
<template>
  <div id="ActionRenewal">
    <!-- 行动换新  -->
    <div class="module_title">
      <Spot
        style="margin-top: 70px; margin-bottom: 177px"
        :SpotZhTitle="SpotZhTitle"
        :SpotEnTitle="SpotEnTitle"
      ></Spot>
    </div>
    <div class="content_box">
      <div class="div">
        <div class="radius_box">
          <div class="center_radius">
            两大品牌为
            <div><span>焕新</span> 生活而来</div>
          </div>
        </div>
      </div>
      <div class="img_left_box">
        <div class="left_image">
          <img :src="getTradeInObj.action_image_one" class="my_cover" alt="" />
        </div>
        <div class="right_text">
          <div class="center_box">
            <div class="min_title">{{ getTradeInObj.action_title_one }}</div>
            <div class="min_info">{{ getTradeInObj.action_info_one }}</div>
          </div>
        </div>
      </div>
      <div class="img_right_box">
        <div class="left_text">
          <div class="center_box">
            <div class="min_title">{{ getTradeInObj.action_title_two }}</div>
            <div class="min_info">{{ getTradeInObj.action_info_two }}</div>
          </div>
        </div>
        <div class="right_image">
          <img :src="getTradeInObj.action_image_two" class="my_cover" alt="" />
        </div>
      </div>
    </div>
    <div class="advertisement">
      <div class="img">
        <img
          src="../../../assets/img/advertisement_img.png"
          class="my_cover"
          alt=""
        />
      </div>
      <div class="text">风雷行动·五大行动换新向前</div>
    </div>
  </div>
</template>

<script>
import Spot from "../../../components/spot";
export default {
  name: "",
  props: {
    getTradeInObj: {
      type: Object,
    },
  },
  components: {
    Spot,
  },
  data() {
    return {
      topscroll: 0,
      SpotZhTitle: "行动换新",
      SpotEnTitle: "Action renewal",
    };
  },
  methods: {
    scrollHandle(e) {
      let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
      this.topscroll = top;
    },
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.scrollHandle); //绑定页面滚动事件
    });
  },
  watch: {
    topscroll(val) {
      if (val > 1145) {
        $("#ActionRenewal .img_left_box .left_image").addClass("left_img");
        $("#ActionRenewal .img_left_box .right_text").addClass("right_texta");
        $("#ActionRenewal .radius_box").addClass("radius_boxa");
      }
      if (val > 1840) {
        $("#ActionRenewal .img_right_box .left_text").addClass("left_texta");
        $("#ActionRenewal .img_right_box .right_image").addClass("right_img");
      }
    },
  },
};
</script>

<style scoped lang='scss'>
#ActionRenewal {
  width: 100%;
  .module_title {
    width: 100%;
    // height: 320px;
  }
  .content_box {
    width: 100%;
    padding-bottom: 120px;
    position: relative;
    .div {
      position: absolute;
      top: 38%;
      width: 100%;
      display: flex;
      justify-content: center;
      .radius_box {
        width: 318px;
        height: 318px;
        background: #ffffff;
        box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.16);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .center_radius {
          width: 270px;
          height: 130px;
          white-space: wrap;
          font-size: 42px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 70px;
          color: #472602;
          text-align: center;
          span {
            color: #223e1c;
          }
        }
      }
    }

    .img_left_box {
      width: 100%;
      height: 700px;
      display: flex;
      .left_image {
        width: 50vw;
        height: 100%;
      }
      .right_text {
        width: 50vw;
        height: 100%;
        .center_box {
          // width: 654px;
          width: 33.59vw;
          height: 366px;
          margin: 94px 0 0 80px;
          .min_title {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 30px;
            color: #666666;
          }
          .min_info {
            margin-top: 30px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 30px;
            color: #666666;
          }
        }
      }
    }
    .img_right_box {
      width: 100%;
      height: 700px;
      display: flex;
      .right_image {
        width: 50vw;
        height: 100%;
      }

      .left_text {
        width: 50vw;
        height: 100%;
        .center_box {
          // width: 654px;
           width: 33.59vw;
          height: 366px;
          margin: 240px 0 0 0;
          margin-left: 11.77vw;
          .min_title {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 30px;
            color: #666666;
          }
          .min_info {
            margin-top: 30px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 30px;
            color: #666666;
          }
        }
      }
    }
  }
  .advertisement {
    width: 100%;
    height: 410px;
    background-image: url("../../../assets/img/advertisement_back.png");
    background-size: cover;
    .img {
      width: 236px;
      height: 124px;
      padding-top: 55px;
      margin: 0 auto 66px auto;
      animation: img 1s linear;
    }
    @keyframes img {
      0% {
        transform: translateY(100%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
    .text {
      font-size: 54px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      letter-spacing: 5px;
      animation: text 1s linear;
    }
    @keyframes text {
      0% {
        transform: translateY(100%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}
</style>
<template>
  <div id="FiveActions">
    <!-- 五大行动 -->
    <div class="first_box">
      <div class="content_box">
        <div class="img_box">
          <img :src="activeImg" alt="" />
        </div>
        <div class="back_box"></div>
        <div class="bar_box">
          <div class="white_line"></div>
          <div class="bar_item" v-for="(item, index) in barData" :key="index">
            <div class="item_title" @click="tobar(index)">{{ item.name }}</div>
            <div
              :class="activeNum === index ? 'spot_box1' : 'spot_box'"
              @click="tobar(index)"
            >
              <div
                :class="activeNum === index ? 'min_stop1' : 'min_stop'"
              ></div>
            </div>
            <div class="item_icon" @click="tobar(index)">
              <img :class="'min_icon' + index" :src="item.icon" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="second_box">
      <Spot
        style="margin-top: 100px"
        :SpotZhTitle="SpotZhTitle"
        :SpotEnTitle="SpotEnTitle"
      ></Spot>
      <div class="five_5">
        <div class="line_"></div>
        <div
          class="item"
          style="cursor: pointer"
          v-for="(item, index) in process"
          :key="index"
        >
          <div class="left" v-if="index % 2 === 0">
            <!-- 左 -->
            <div class="kong"></div>
            <div class="border_spot">{{ index + 1 }}</div>
            <div class="box">
              <div class="bor_back_box">
                <div class="title">{{ item.name }}</div>
              </div>
              <div class="z_line"></div>
              <div class="name">
                <div class="big_info">{{ item.info }}</div>
                <div class="min_info" v-if="item.min_info">
                  {{ item.min_info }}
                </div>
              </div>
            </div>
          </div>
          <div class="right" v-if="index % 2 !== 0">
            <!-- 右 -->
            <div class="box">
              <div class="name">
                <div class="big_info">{{ item.info }}</div>
                <div class="min_info" v-if="item.min_info">
                  {{ item.min_info }}
                </div>
              </div>
              <div class="z_line"></div>
              <div class="bor_back_box">
                <div class="title">{{ item.name }}</div>
              </div>
            </div>
            <div class="border_spot">{{ index + 1 }}</div>
            <div class="kong"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spot from "../../../components/spot";
export default {
  name: "",
  props: {
    getTradeInObj: {
      type: Object,
    },
  },
  components: {
    Spot,
  },
  data() {
    return {
      SpotZhTitle: "换购流程介绍",
      SpotEnTitle: "Introduction to exchange purchase process",
      activeImg: "",
      activeNum: 0,
      process: [
        {
          name: "换新购买",
          info: "客户购买新床垫",
        },
        {
          name: "送货安装",
          info: "专业人员送货上门安装，旧床垫免费搬运并拉走",
        },
        // {
        //   name: "公益回购",
        //   info: "回购客户旧床垫",
        //   min_info: "回购客户任意旧床垫均抵扣现金400元",
        // },
        {
          name: "返运仓储",
          info: "返回八益环保仓",
        },
        {
          name: "环保处理",
          info: "统一集中环保无公害处理",
        },
      ],
      barData: [
        {
          icon: require("../../../assets/img/adv1.png"),
          name: "服务换新",
        },
        {
          icon: require("../../../assets/img/adv2.png"),
          name: "生产工艺换新",
        },
        {
          icon: require("../../../assets/img/adv3.png"),
          name: "环保换新",
        },
        {
          icon: require("../../../assets/img/adv4.png"),
          name: "终端换新",
        },
        {
          icon: require("../../../assets/img/adv5.png"),
          name: "产品换新",
        },
      ],
    };
  },
  methods: {
    tobar(index) {
      this.activeNum = index;
      if (index == 0) {
        this.activeImg = this.getTradeInObj.image_one;
      }
      if (index == 1) {
        this.activeImg = this.getTradeInObj.image_two;
      }
      if (index == 2) {
        this.activeImg = this.getTradeInObj.image_three;
      }
      if (index == 3) {
        this.activeImg = this.getTradeInObj.image_four;
      }
      if (index == 4) {
        this.activeImg = this.getTradeInObj.image_five;
      }
    },
  },
  mounted() {},
  watch: {
    getTradeInObj(val) {
      if (this.activeNum == 0) {
        this.activeImg = val.image_one;
      }
      if (this.activeNum == 1) {
        this.activeImg = val.image_two;
      }
      if (this.activeNum == 2) {
        this.activeImg = val.image_three;
      }
      if (this.activeNum == 3) {
        this.activeImg = val.image_four;
      }
      if (this.activeNum == 4) {
        this.activeImg = val.image_five;
      }
    },
  },
  computed: {},
  filters: {},
};
</script>

<style scoped lang='scss'>
#FiveActions {
  width: 100%;
  background-image: url("../../../assets/img/fiveActions_back.png");
  background-size: cover;
  background-position-x: -280px;
  background-size: 120% 100%;
  .first_box {
    width: 1206px;
    margin: 0 auto;
    .content_box {
      margin-top: 100px;
      width: 100%;
      height: 654px;
      position: relative;
      .back_box {
        position: absolute;
        right: 0;
        bottom: 0;
        background: #c8a86a;
        width: 1140px;
        height: 593px;
      }
      .img_box {
        width: 1140px;
        height: 593px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 9;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .bar_box {
        width: 874px;
        position: absolute;
        right: 107px;
        bottom: 7px;
        height: 166px;
        z-index: 10;
        display: flex;
        .white_line {
          position: absolute;
          top: 69px;
          width: 100%;
          height: 2px;
          background: #fff;
          z-index: 999;
        }
        .bar_item:last-child {
          margin-right: 0;
        }
        .bar_item {
          height: 100%;
          margin-right: 80px;
          cursor: pointer;
          .spot_box {
            width: 19px;
            height: 19px;
            border: 2px solid #ffffff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin: 35px auto 29px auto;
            .min_stop {
              width: 9px;
              height: 9px;
              background: #ffffff;
              border-radius: 50%;
            }
          }
          .spot_box1 {
            width: 19px;
            height: 19px;
            border: 2px solid #225928;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin: 35px auto 29px auto;
            .min_stop1 {
              width: 9px;
              height: 9px;
              background: #225928;
              border-radius: 50%;
            }
          }
          .item_title {
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #d8c9af;
          }
          .item_icon {
            width: 52px;
            height: 52px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            .min_icon0 {
              width: 100%;
              height: 100%;
              display: block;
            }
            .min_icon1 {
              width: 338.8px;
              height: 33.09px;
              display: block;
            }
            .min_icon2 {
              width: 35.79px;
              height: 26.75px;
              display: block;
            }
            .min_icon3 {
              width: 29.8px;
              height: 29.8px;
              display: block;
            }
            .min_icon4 {
              width: 30.91px;
              height: 31.73px;
              display: block;
            }
          }
        }
      }
    }
  }
  .second_box {
    width: 100%;
    // height: 1543px;
    //height: 1360px;
    height: 1138px;
    // background-image: url("../../../assets/img/fiveActions_back.png");
    //   background-size: cover;
    //   background-position-x: -280px;
    //   background-size: 120% 100%;
    .five_5 {
      height: 1303px;
      width: 1047px;
      margin: 0 auto;
      margin-top: 47px;
      position: relative;
      .line_ {
        width: 2px;
        // height: 1224px; 
        //height: 1164px;
        height: 966px;
        background: #225928;
        position: absolute;
        left: 49.9%;
      }
      .item {
        width: 100%;
        margin-bottom: 103px;
        .left {
          width: 100%;
          height: 164px;
          display: flex;
          transition: all 0.3s linear;
          justify-content: space-between;
          align-items: center;
          .kong {
            width: 485px;
            height: 164px;
            background: transparent;
          }
          .border_spot {
            width: 30px;
            height: 30px;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 30px;
            color: #ffffff;
            // background: #c8a86a;
            background: #999999;
            border-radius: 50%;
            z-index: 9;
            text-align: center;
          }
          .box {
            width: 485px;
            height: 164px;
            border: 1px solid #999999;
            border-radius: 90px;
            background: #fff;
            display: flex;
            align-items: center;
            .bor_back_box {
              width: 146px;
              height: 146px;
              border-radius: 50%;
              border: 5px solid #999999;
              // background: #e3d6bd;
              background: #fff;
              margin: 0 25px 0 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              .title {
                width: 62px;
                height: 80px;
                font-size: 30px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 40px;
                color: #999999;
                white-space: wrap;
              }
            }
            .z_line {
              background: #999999;
              width: 2px;
              height: 38px;
              margin-right: 15px;
            }
            .name {
              width: 244px;
              .big_info {
                font-size: 26px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 40px;
                // color: #884a08;
                color: #999;
                white-space: wrap;
              }
              .min_info {
                font-size: 15px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 20px;
                // color: #884a08;
                color: #999;
              }
            }
          }
        }
        .left:hover {
          transition: all 0.3s linear;
          .border_spot {
            background: #c8a86a;
          }
          .box {
            border: 1px solid #c8a86a;
            .bor_back_box {
              border: 5px solid #c8a86a;
              background: #e3d6bd;
            }
          }
          .z_line {
            background: #884a08;
          }
          .name {
            .big_info {
              color: #884a08;
            }
            .min_info {
              color: #884a08;
            }
          }
        }
        .right:hover {
          transition: all 0.3s linear;
          .border_spot {
            background: #c8a86a;
          }
          .box {
            border: 1px solid #c8a86a;
            .bor_back_box {
              border: 5px solid #c8a86a;
              background: #e3d6bd;
            }
            .z_line {
              background: #884a08;
            }
            .name {
              .big_info {
                color: #884a08;
              }
              .min_info {
                color: #884a08;
              }
            }
          }
        }
        .right {
          transition: all 0.3s linear;
          width: 100%;
          height: 164px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .kong {
            // width: 485px;
            width: 488px;
            height: 164px;
            background: transparent;
          }
          .border_spot {
            width: 30px;
            height: 30px;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 30px;
            color: #ffffff;
            background: #999999;
            border-radius: 50%;
            z-index: 9;
            text-align: center;
          }
          .box {
            width: 485px;
            height: 164px;
            // border: 1px solid #c8a86a;
            border: 1px solid #999;
            border-radius: 90px;
            background: #fff;
            display: flex;
            align-items: center;
            position: relative;
            .bor_back_box {
              width: 146px;
              height: 146px;
              border-radius: 50%;
              // border: 5px solid #c8a86a;
              // background: #e3d6bd;
              border: 5px solid #999;
              background: #fff;
              margin: 0 25px 0 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              right: -15px;
              .title {
                width: 62px;
                height: 80px;
                font-size: 30px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 40px;
                // color: #884a08;
                color: #999;
                white-space: wrap;
              }
            }
            .z_line {
              background: #999;
              width: 2px;
              height: 38px;
              margin-right: 15px;
              position: absolute;
              right: 165px;
            }
            .name {
              width: 244px;
              position: absolute;
              right: 190px;
              text-align: right;
              .big_info {
                font-size: 26px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 40px;
                color: #999;
                white-space: wrap;
              }
              .min_info {
                font-size: 15px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                line-height: 20px;
                color: #884a08;
              }
            }
          }
        }
      }
      .item:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>